import validator from 'validator';

export const validateYearlyBusinessTaxes = (data) => {
  const errors = {};

  const numericFields = [
    'totalSales',
    'cityPropertyTax',
    'countyPropertyTax',
    'citySalesServiceReceivedByPersonsLocatedInOtherStates',
    'countySalesServiceReceivedByPersonsLocatedInOtherStates',
    'cityReturnedMerchandiseSalePriceRefundedToCustomer',
    'countyReturnedMerchandiseSalePriceRefundedToCustomer',
    'citySalesTangiblePersonalPropertyInInterstateCommerce',
    'countySalesTangiblePersonalPropertyInInterstateCommerce',
    'cityCashDiscountsAllowedAndTakenOnSales',
    'countyCashDiscountsAllowedAndTakenOnSales',
    'cityBadDebtsWrittenDuringReportingPeriod',
    'countyBadDebtsWrittenDuringReportingPeriod',
    'cityFederalAndTennesseeGasolineTax',
    'countyFederalAndTennesseeGasolineTax',
    'cityFederalAndTennesseeMotorFuelTax',
    'countyFederalAndTennesseeMotorFuelTax',
    'cityFederalAndTennesseeTobaccoTaxOnCigarettes',
    'countyFederalAndTennesseeTobaccoTaxOnCigarettes',
    'cityFederalAndTennesseeTobaccoTaxOnOtherTobaccoProducts',
    'countyFederalAndTennesseeTobaccoTaxOnOtherTobaccoProducts',
    'cityFederalAndTennesseeBeerTax',
    'countyFederalAndTennesseeBeerTax',
    'cityTennesseeSpecialTaxOnPetroleumProducts',
    'countyTennesseeSpecialTaxOnPetroleumProducts',
    'cityTennesseeLiquifiedGasTaxForCertainMotorVehicles',
    'countyTennesseeLiquifiedGasTaxForCertainMotorVehicles',
    'cityTennesseeBeerWholesaleTax',
    'countyTennesseeBeerWholesaleTax',
    'cityTennesseeBailBondsTax',
    'countyTennesseeBailBondsTax',
    'cityOtherDeductionsNotTakenElsewhereOnReturn',
    'countyOtherDeductionsNotTakenElsewhereOnReturn',
    'countyTotalDeductions',
    'cityTotalDeductions',
    'countyGrossTax',
    'cityGrossTax',
    'countyNetTax',
    'cityNetTax'
  ];

  numericFields.forEach((field) => {
    errors[field] =
      !validator.isEmpty(String(data[field] || '')) &&
      (!validator.isNumeric(String(data[field])) ||
        !validator.isFloat(String(data[field]))) &&
      `${field} must be a numeric value`;
  });

  // Remove null, undefined, or false values
  for (const key in errors) {
    if (!errors[key] || errors[key] === false) {
      delete errors[key];
    }
  }

  return errors;
};
