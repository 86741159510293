export const reasonsForInactive = [
  'Discontinued',
  'Sold',
  'Never Started',
  'Other -Specify'
];
export const businessStructures = [
  'Sole Proprietorship',
  'Partnership',
  'Corporation',
  'S Corporation',
  'Limited Liability Company',
  'Trust',
  'Estate',
  'Other'
];
export const taxClassifications = [
  'Sole - Form 1040',
  'Partnership - Form 1065',
  'C Corporation - Form 1120',
  'S Corporation- Form 1120S',
  'Blank',
  'Other'
];

export const states = [
  'ALABAMA',
  'ALASKA',
  'ARIZONA',
  'ARKANSAS',
  'CALIFORNIA',
  'COLORADO',
  'CONNECTICUT',
  'DELAWARE',
  'FLORIDA',
  'GEORGIA',
  'HAWAII',
  'IDAHO',
  'ILLINOIS',
  'INDIANA',
  'IOWA',
  'KANSAS',
  'KENTUCKY',
  'LOUISIANA',
  'MAINE',
  'MARYLAND',
  'MASSACHUSETTS',
  'MICHIGAN',
  'MINNESOTA',
  'MISSISSIPPI',
  'MISSOURI',
  'MONTANA',
  'NEBRASKA',
  'NEVADA',
  'NEW HAMPSHIRE',
  'NEW JERSEY',
  'NEW MEXICO',
  'NEW YORK',
  'NORTH CAROLINA',
  'NORTH DAKOTA',
  'OHIO',
  'OKLAHOMA',
  'OREGON',
  'PENNSYLVANIA',
  'RHODE ISLAND',
  'SOUTH CAROLINA',
  'SOUTH DAKOTA',
  'TENNESSEE',
  'TEXAS',
  'UTAH',
  'VERMONT',
  'VIRGINIA',
  'WASHINGTON',
  'WEST VIRGINIA',
  'WISCONSIN',
  'WYOMING'
];

export const businessTypes = ['Retailer', 'Wholesaler'];

export const classifications = [
  'Classification 1(A)',
  'Classification 1(B)',
  'Classification 1(C)',
  'Classification 1(D)',
  'Classification 1(E)',
  'Classification 2',
  'Classification 3',
  'Classification 4',
  'Classification 5(A)',
  'Classification 5(B)'
];

export const payrollSchedules = [
  'Weekly',
  'Bi-Weekly',
  'Semi-Monthly',
  'Monthly',
  'Weekly and Monthly',
  'Biweekly and Monthly'

];

export const payrollDeliveryMethods = [
  'Mail checks',
  'Email PDF checks',
  'Email stubs',
  'Mail checks & email stubs',
  'Direct deposit',
  'Direct deposit & email stubs',
  'Direct deposit & checks',
  'Create only',
  'pickup'
];

export const payrollW2DeliveryMethods = [
  'E-mail',
  'Paper mail',
];

export const payrollProcessingDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
  '1st of the month',
  '15th of the month',
  'Last day of the month'
];

export const ownershipTitles = [
  'Shareholder',
  'General Partner',
  'Limited Partner',
  'Sole Proprietor'
];

export const industries = [
  'Gasoline Station',
  'Gasoline Station with Convenience Store',
  'Convenience Store',
  'Grocery Store',
  'Supermarket',
  'Restaurant',
  'Beer, Wine & Liquor Store',
  'Tobacco Store',
  'Motel/Hotel',
  'Other-Specify'
];
