import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Modal from '@mui/material/Modal';
import { Close as CloseIcon, ContactPhoneOutlined } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import ViewField from './viewField';
import { formatNumber } from 'src/utils/helperFunctions';
import { formatClientId } from 'src/utils/helperFunctions';
import { getPreviousSales } from 'src/services/salesService';

const ViewSales = ({ open, onClose, viewSales = {} }) => {
  const [previousMonthSale, setPreviousMonthSale]: any = useState({});
  const [sale, setSale]: any = useState({});
  const theme = useTheme();
  const getPreviousSalesData = async ({ year, month, clientId }) => {
    try {
      if (!year || !month || !clientId) return;
      const data = await getPreviousSales({ year, month, clientId });
      if (data && data.meta && data.meta.status === 200) {
        setPreviousMonthSale(data.data);
      }
    } catch (error) {
      setPreviousMonthSale({});
      console.error('Error fetching clients:', error);
    }
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    let sale: any = viewSales;
    if (sale) {
      sale = {
        ...sale,
        otherDeductions:
          Number(sale.gasolineAmount) +
          Number(sale.carWash) +
          Number(sale.newsPaper) +
          Number(sale.exemptFood) +
          Number(sale.otherExempt),
        totalExempt:
          Number(sale.gasolineAmount) +
          Number(sale.totalExempt) +
          Number(sale.totalLowTax)
      };
    }

    setSale(sale);
    getPreviousSalesData({
      year: sale?.year,
      month: sale?.month,
      clientId: sale?.client && sale.client.id
    });
  }, [viewSales]);
  console.log(
    formatNumber(
      sale?.carWash +
        sale?.newsPaper +
        sale?.exemptFood +
        sale?.saleTaxHoliday +
        sale?.otherExempt +
        sale?.ebt +
        sale?.gasolineAmount
    )
  );
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          // Other styles...
          backdropFilter: 'blur(8px)', // Adjust blur intensity as needed
          WebkitBackdropFilter: 'blur(8px)' // For Safari support
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%', // Adjust this percentage as needed
            height: '85%', // Adjust this percentage as needed
            bgcolor: 'background.paper',
            borderRadius: 4,
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
            overflowX: 'hidden',
            border: 'none'
            // '&::-webkit-scrollbar': {
            //   display: 'none'
            // },
            // msOverflowStyle: 'none',
            // scrollbarWidth: 'none'
          }}
        >
          <CloseIcon
            sx={{
              position: 'sticky',
              top: -25,
              left: '100%',
              cursor: 'pointer',
              zIndex: 2,
              backgroundColor: 'background.paper',
              color: 'red'
            }}
            onClick={() => handleClose()}
          />

          <Grid container sx={{ mx: 4, py: 2 }}>
            <ViewField
              label={'Client ID'}
              // value1={(sale && sale.client && sale.client.id) || ''}
              value1={
                (sale && sale.client && formatClientId(sale.client.id)) || ''
              }
              value2={undefined}
            />
            <ViewField
              label={'Federal Tax ID'}
              value1={(sale && sale.client && sale.client.federalTaxId) || ''}
              value2={undefined}
            />
            <ViewField
              label={'Legal Business Name'}
              value1={
                (sale && sale.client && sale.client.legalBusinessName) || ''
              }
              value2={undefined}
            />
            <ViewField
              label={'Doing Business AS'}
              value1={
                (sale && sale.client && sale.client.doingBusinessAs) || ''
              }
              value2={undefined}
            />
            <ViewField
              label={'State'}
              value1={(sale && sale.client && sale.client.state) || ''}
              value2={undefined}
            />
            <ViewField
              label={'County'}
              value1={(sale && sale.client && sale.client.county) || ''}
              value2={undefined}
            />
            <ViewField
              label={'City'}
              value1={(sale && sale.client && sale.client.city) || ''}
              value2={undefined}
            />
            <ViewField
              label={'Sales Tax ID'}
              value1={
                (sale &&
                  sale.client &&
                  sale.client.salesTaxes &&
                  sale.client.salesTaxes.salesTaxId) ||
                ''
              }
              value2={undefined}
            />
            <Grid item xs={12}>
              <br></br>
            </Grid>
            <ViewField
              label={'Month / Year'}
              value1={(sale && `${sale.month} - ${sale.year}`) || ''}
              value2={undefined}
            />
            <Grid item xs={12}>
              <br></br>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <div>
                    <Typography
                      variant="body1"
                      color={'Red'}
                      fontWeight={'bold'}
                    >
                      Permanent Notes
                    </Typography>
                    <Typography variant="body1" color={'Red'}>
                      {sale?.client?.salesTaxes?.importantNotes ?? ''}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" color={'Red'} fontWeight={'bold'}>
                    Temporary Notes
                  </Typography>
                  <Typography variant="body1" color={'Red'}>
                    {sale?.temporaryNotes ?? ''}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <br></br>
            </Grid>
            {sale &&
              sale.client &&
              sale.client.state !== 'ARKANSAS' &&
              sale.client.state !== 'MISSOURI' && (
                <ViewField
                  label={'Gross Sales'}
                  value1={formatNumber(sale && sale.totalSales) || ''}
                  value2={undefined}
                />
              )}
            {sale &&
              sale.client &&
              sale.client.state !== 'ARKANSAS' &&
              sale.client.state !== 'MISSOURI' &&
              sale.client.state !== 'MISSISSIPPI' && (
                <Grid item xs={12}>
                  <br></br>
                </Grid>
              )}
            {sale &&
              sale.client &&
              sale.client.state !== 'ARKANSAS' &&
              sale.client.state !== 'MISSOURI' &&
              sale.client.state !== 'MISSISSIPPI' && (
                <Grid item xs={12} sm={12}>
                  <Box p={0} mt={1}>
                    <Typography
                      variant="h5"
                      color={'black'}
                      fontWeight={'bold'}
                    >
                      Temporary Exemptions
                    </Typography>
                  </Box>
                </Grid>
              )}
            {sale &&
              sale.client &&
              sale.client.state !== 'ARKANSAS' &&
              sale.client.state !== 'MISSOURI' &&
              sale.client.state !== 'MISSISSIPPI' && (
                <ViewField
                  label={'Sales Tax Holiday'}
                  value1={formatNumber(sale && sale.saleTaxHoliday) || ''}
                  value2={undefined}
                />
              )}
            {sale &&
              sale.client &&
              sale.client.state !== 'ARKANSAS' &&
              sale.client.state !== 'MISSOURI' &&
              sale.client.state !== 'MISSISSIPPI' && (
                <Grid item xs={12}>
                  <br></br>
                </Grid>
              )}
            {sale &&
              sale.client &&
              sale.client.state !== 'ARKANSAS' &&
              sale.client.state !== 'MISSOURI' &&
              sale.client.state !== 'MISSISSIPPI' && (
                <Grid item xs={12} sm={12}>
                  <Box p={0} mt={1}>
                    <Typography
                      variant="h5"
                      color={'black'}
                      fontWeight={'bold'}
                    >
                      Exemptions
                    </Typography>
                  </Box>
                </Grid>
              )}
            {sale &&
              sale.client &&
              sale.client.state !== 'ARKANSAS' &&
              sale.client.state !== 'MISSOURI' &&
              sale.client.state !== 'MISSISSIPPI' && (
                <ViewField
                  label={'Food Sales'}
                  value1={formatNumber(sale && sale.totalLowTax) || ''}
                  value2={undefined}
                />
              )}
            {sale &&
              sale.client &&
              sale.client.state !== 'ARKANSAS' &&
              sale.client.state !== 'MISSOURI' &&
              sale.client.state !== 'MISSISSIPPI' && (
                <ViewField
                  label={'EBT Sales'}
                  value1={formatNumber(sale && sale.ebt) || ''}
                  value2={undefined}
                />
              )}
            {sale &&
              sale.client &&
              sale.client.state !== 'ARKANSAS' &&
              sale.client.state !== 'MISSOURI' &&
              sale.client.state !== 'MISSISSIPPI' && (
                <ViewField
                  label={'Other Deductions'}
                  value1={formatNumber(sale && sale.otherDeductions) || ''}
                  value2={undefined}
                />
              )}
            {sale &&
              sale.client &&
              sale.client.state !== 'ARKANSAS' &&
              sale.client.state !== 'MISSOURI' &&
              sale.client.state !== 'MISSISSIPPI' && (
                <Grid item xs={12}>
                  <br></br>
                </Grid>
              )}
            {sale &&
              sale.client &&
              sale.client.state !== 'ARKANSAS' &&
              sale.client.state !== 'MISSOURI' &&
              sale.client.state !== 'MISSISSIPPI' && (
                <ViewField
                  label={'Total Exemptions'}
                  value1={formatNumber(sale && sale.totalExempt) || ''}
                  value2={undefined}
                />
              )}
            {sale &&
              sale.client &&
              sale.client.state !== 'ARKANSAS' &&
              sale.client.state !== 'MISSOURI' && (
                <Grid item xs={12}>
                  <br></br>
                </Grid>
              )}
            {sale &&
              sale.client &&
              sale.client.state !== 'ARKANSAS' &&
              sale.client.state !== 'MISSOURI' &&
              sale.client.state !== 'MISSISSIPPI' && (
                <ViewField
                  label={'Net Taxable Sales'}
                  value1={
                    formatNumber(sale && sale.client && sale.totalHighTax) || ''
                  }
                  value2={formatNumber(
                    ((sale && sale.client && sale.totalHighTax) / 100) *
                      ((sale &&
                        sale.client &&
                        sale.client.salesTaxes &&
                        sale.client.salesTaxes.stateHighTax) ||
                        0)
                  )}
                />
              )}
            {sale &&
              sale.client &&
              sale.client.state !== 'ARKANSAS' &&
              sale.client.state !== 'MISSOURI' &&
              sale.client.state !== 'MISSISSIPPI' && (
                <ViewField
                  label={'Food Sales'}
                  value1={
                    formatNumber(sale && sale.client && sale.totalLowTax) || ''
                  }
                  value2={formatNumber(
                    ((sale && sale.client && sale.totalLowTax) / 100) *
                      ((sale &&
                        sale.client &&
                        sale.client.salesTaxes &&
                        sale.client.salesTaxes.stateLowTax) ||
                        0)
                  )}
                />
              )}
            {sale &&
              sale.client &&
              sale.client.state !== 'ARKANSAS' &&
              sale.client.state !== 'MISSOURI' &&
              sale.client.state !== 'MISSISSIPPI' && (
                <ViewField
                  label={'City/County Taxes '}
                  value1={
                    formatNumber(
                      Number(sale && sale.client && sale.totalLowTax) +
                        Number(sale && sale.client && sale.totalHighTax)
                    ) || ''
                  }
                  value2={formatNumber(
                    ((Number(sale && sale.client && sale.totalLowTax) +
                      Number(sale && sale.client && sale.totalHighTax)) /
                      100) *
                      ((sale &&
                        sale.client &&
                        sale.client.salesTaxes &&
                        sale.client.salesTaxes.cityCountyTax) ||
                        0)
                  )}
                />
              )}
            {sale &&
              sale.client &&
              sale.client.state !== 'ARKANSAS' &&
              sale.client.state !== 'MISSOURI' &&
              sale.client.state !== 'MISSISSIPPI' && (
                <ViewField
                  label={'CBD Tax'}
                  value1={
                    formatNumber(sale && sale.client && sale.cbdDelta) || ''
                  }
                  value2={formatNumber(
                    ((sale && sale.client && sale.cbdDelta) / 100) *
                      ((sale &&
                        sale.client &&
                        sale.client.salesTaxes &&
                        sale.client.salesTaxes.cbdTax) ||
                        0)
                  )}
                />
              )}
            {/* Only show in Arkansas */}
            {sale && sale.client && sale.client.state === 'ARKANSAS' && (
              <ViewField
                label={'High Tax Sales'}
                value1={formatNumber(sale && sale.totalHighTax) || ''}
                value2={formatNumber(sale && sale.stateHighTax) || ''}
              />
            )}
            {sale && sale.client && sale.client.state === 'ARKANSAS' && (
              <ViewField
                label={'Low Tax Sales'}
                value1={formatNumber(sale && sale.totalLowTax) || ''}
                value2={formatNumber(sale && sale.stateLowTax) || ''}
              />
            )}
            {sale && sale.client && sale.client.state === 'ARKANSAS' && (
              <ViewField
                label={'Liquor Sales'}
                value1={formatNumber(sale && sale.wine) || ''}
                value2={formatNumber(sale && sale.liquorTax) || ''}
              />
            )}
            {sale && sale.client && sale.client.state === 'ARKANSAS' && (
              <ViewField
                label={'Beer Sales'}
                value1={formatNumber(sale && sale.beer) || ''}
                value2={formatNumber(sale && sale.beerTax) || ''}
              />
            )}
            {sale &&
              sale.client &&
              sale.client.state !== 'MISSOURI' &&
              sale.client.state !== 'MISSISSIPPI' && (
                <ViewField
                  label={'911 Fees '}
                  value1={formatNumber(sale && sale.fees911) || ''}
                  value2={formatNumber(
                    (sale && sale.fees911) *
                      ((sale &&
                        sale.client &&
                        sale.client.salesTaxes &&
                        sale.client.salesTaxes.fees911) ||
                        0)
                  )}
                />
              )}
            {sale && sale.client && sale.client.state === 'ARKANSAS' && (
              <ViewField
                label={'County Taxes '}
                value1={
                  formatNumber(
                    sale && +sale.totalHighTax + +sale.totalLowTax
                  ) || ''
                }
                value2={formatNumber(sale && sale.countyTax) || ''}
              />
            )}
            {sale && sale.client && sale.client.state === 'ARKANSAS' && (
              <ViewField
                label={'City Taxes '}
                value1={
                  formatNumber(
                    sale && +sale.totalHighTax + +sale.totalLowTax
                  ) || ''
                }
                value2={formatNumber(sale && sale.cityTax) || ''}
              />
            )}
            {sale && sale.client && sale.client.state === 'ARKANSAS' && (
              <ViewField
                label={'Discount '}
                value1={formatNumber(sale && sale.discount) || ''}
                value2={null}
              />
            )}
            {sale && sale.client && sale.client.state === 'ARKANSAS' && (
              <ViewField
                label={'Discount '}
                value1={formatNumber(sale && sale.salesTaxPayable) || ''}
                value2={null}
              />
            )}
            {sale && sale.client && sale.client.state === 'ARKANSAS' && (
              <Grid item xs={12}>
                <br></br>
              </Grid>
            )}
            {sale &&
              sale.client &&
              sale.client.state !== 'MISSOURI' &&
              sale.client.state !== 'MISSISSIPPI' && (
                <ViewField
                  label={'Total Sales Tax'}
                  value1={''}
                  value2={
                    formatNumber(sale && sale.client && sale.totalSalesTax) ||
                    ''
                  }
                />
              )}
            {/* Only Show in MISSOURI */}
            {sale?.client?.state === 'MISSOURI' && (
              <>
                {/* High Tax Sale Section */}
                <Grid item xs={12} sm={12}>
                  <Box p={0} mt={1}>
                    <Typography variant="h5" color="black" fontWeight="bold">
                      High Tax Sale
                    </Typography>
                  </Box>
                </Grid>

                <ViewField
                  label="Gross Receipts"
                  value1={formatNumber(
                    +sale.totalHighTax + +sale.gasolineAmount
                  )}
                  value2={undefined}
                />
                <ViewField
                  label="Adjustment"
                  value1={formatNumber(-sale.gasolineAmount)}
                  value2={undefined}
                />
                <ViewField
                  label="Taxable Sales"
                  value1={formatNumber(+sale.totalHighTax)}
                  value2={undefined}
                />
                <ViewField
                  label="Tax Rate"
                  value1={formatNumber(
                    +sale.client.salesTaxes.stateHighTax +
                      +sale.client.salesTaxes.cityTax +
                      +sale.client.salesTaxes.countyTax
                  )}
                  value2={undefined}
                />
                <ViewField
                  label="Amount Of Tax"
                  value1={formatNumber(
                    (+sale.totalHighTax *
                      (+sale.client.salesTaxes.stateHighTax +
                        +sale.client.salesTaxes.cityTax +
                        +sale.client.salesTaxes.countyTax)) /
                      100
                  )}
                  value2={undefined}
                />

                {/* Low Tax Sale Section */}
                <Grid item xs={12} sm={12}>
                  <Box p={0} mt={1}>
                    <Typography variant="h5" color="black" fontWeight="bold">
                      Low Tax Sale
                    </Typography>
                  </Box>
                </Grid>

                <ViewField
                  label="Gross Receipts"
                  value1={formatNumber(+sale.totalLowTax + +sale.totalExempt)}
                  value2={undefined}
                />
                <ViewField
                  label="Adjustment"
                  value1={formatNumber(-sale.totalExempt)}
                  value2={undefined}
                />
                <ViewField
                  label="Taxable Sales"
                  value1={formatNumber(+sale.totalLowTax)}
                  value2={undefined}
                />
                <ViewField
                  label="Tax Rate"
                  value1={formatNumber(
                    +sale.client.salesTaxes.stateLowTax +
                      +sale.client.salesTaxes.cityTax +
                      +sale.client.salesTaxes.countyTax
                  )}
                  value2={undefined}
                />
                <ViewField
                  label="Amount Of Tax"
                  value1={formatNumber(
                    (+sale.totalLowTax *
                      (+sale.client.salesTaxes.stateLowTax +
                        +sale.client.salesTaxes.cityTax +
                        +sale.client.salesTaxes.countyTax)) /
                      100
                  )}
                  value2={undefined}
                />
                <Grid item xs={12}>
                  <br></br>
                </Grid>

                {/* Total Sales Tax */}
                <ViewField
                  label="Total Sales Tax"
                  value1={formatNumber(
                    (+sale.totalHighTax *
                      (+sale.client.salesTaxes.stateHighTax +
                        +sale.client.salesTaxes.cityTax +
                        +sale.client.salesTaxes.countyTax)) /
                      100 +
                      (+sale.totalLowTax *
                        (+sale.client.salesTaxes.stateLowTax +
                          +sale.client.salesTaxes.cityTax +
                          +sale.client.salesTaxes.countyTax)) /
                        100
                  )}
                  value2={undefined}
                />
                <ViewField
                  label="Discount"
                  value1={formatNumber(sale.discount)}
                  value2={null}
                />
                <ViewField
                  label="Net Sales Tax"
                  value1={formatNumber(sale.netSalesTax)}
                  value2={null}
                />
              </>
            )}
            {sale?.client?.state === 'MISSISSIPPI' && (
              <>
                <Grid item xs={12} sm={12}>
                  <Box p={0} mt={1}>
                    <Typography variant="h5" color="black" fontWeight="bold">
                      Deductions
                    </Typography>
                  </Box>
                </Grid>

                {/* Individual deduction fields */}
                <ViewField
                  label="Sales of Motor Fuels"
                  value1={formatNumber(sale?.gasolineAmount) || ''}
                  value2={undefined}
                />
                <ViewField
                  label="Purchases made with SNAP EBT Card"
                  value1={formatNumber(sale?.ebt) || ''}
                  value2={undefined}
                />

                {(() => {
                  // Calculate "Other Non-Taxable Sales"
                  const otherNonTaxableSales =
                    (Number(sale?.saleTaxHoliday) || 0) +
                    (Number(sale?.otherExempt) || 0) +
                    (Number(sale?.exemptFood) || 0) +
                    (Number(sale?.newsPaper) || 0) +
                    (Number(sale?.carWash) || 0);

                  // Total deductions
                  const totalDeductions =
                    otherNonTaxableSales +
                    (Number(sale?.gasolineAmount) || 0) +
                    (Number(sale?.ebt) || 0);
                  const taxableGrossIncome =
                    (Number(sale?.totalSales) || 0) - totalDeductions;
                  const calculatedTax = taxableGrossIncome * 0.07;
                  const specialTax = Number(sale?.specialTax);
                  const totalSalesTax = sale?.totalSalesTax;
                  const discount = Math.min(totalSalesTax * 0.02, 50);
                  const excessTaxCollected = Number(sale?.excessTaxCollected);
                  const credit = Number(sale?.credit);
                  const netSalesTax =
                    totalSalesTax - discount + excessTaxCollected - credit;

                  return (
                    <>
                      <ViewField
                        label="Other Non-Taxable Sales"
                        value1={formatNumber(otherNonTaxableSales)}
                        value2={undefined}
                      />
                      <ViewField
                        label="Total Deductions"
                        value1={formatNumber(totalDeductions)}
                        value2={undefined}
                      />
                      <Grid item xs={12}>
                        <br></br>
                      </Grid>
                      <ViewField
                        label="Taxable Gross Income"
                        value1={formatNumber(taxableGrossIncome)}
                        value2={undefined}
                      />
                      <ViewField
                        label="Calculated Tax"
                        value1={formatNumber(calculatedTax)}
                        value2={undefined}
                      />
                      <ViewField
                        label="Special Tax"
                        value1={formatNumber(specialTax)}
                        value2={undefined}
                      />
                      <ViewField
                        label="Total Sales Tax"
                        value1={formatNumber(totalSalesTax)}
                        value2={undefined}
                      />
                      <ViewField
                        label="Discount"
                        value1={formatNumber(discount)}
                        value2={undefined}
                      />
                      <ViewField
                        label="Excess Tax Collected"
                        value1={formatNumber(excessTaxCollected)}
                        value2={undefined}
                      />
                      <ViewField
                        label="Credit"
                        value1={formatNumber(credit)}
                        value2={undefined}
                      />
                      <ViewField
                        label="Net Sales Tax"
                        value1={formatNumber(netSalesTax)}
                        value2={undefined}
                      />
                    </>
                  );
                })()}
              </>
            )}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ViewSales;
