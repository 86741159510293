import React from 'react';
import { Grid, TextField, Typography } from '@mui/material';

const CountyCityInput = ({
  label,
  countyValue,
  cityValue,
  handleInputChange,
  countyValidationField,
  cityValidationField,
  name,
  placeholder = '',
  required = false,
  readOnly = false,
  props = {},
  showLabels = false
}) => {
  return (
    <Grid container spacing={1} alignItems="center" sx={{ width: '100%' }}>
      {label && (
        <Grid item xs={12} sm={7}>
          <Typography variant="body1">{label}</Typography>
        </Grid>
      )}
      <Grid item xs={6} sm={2.5}>
        <TextField
          label={showLabels ? 'County' : ''}
          value={countyValue || ''}
          variant="outlined"
          size="small"
          onChange={(e) => handleInputChange(`county${name}`, e.target.value)}
          error={!!countyValidationField}
          helperText={countyValidationField}
          required={required}
          InputProps={{
            readOnly: readOnly
          }}
          InputLabelProps={{
            sx: { fontSize: '12px' } // Change font size here
          }}
          {...props}
          sx={{
            '& .MuiInputBase-root': {
              height: 28
            }
          }}
        />
      </Grid>
      <Grid item xs={6} sm={2.5}>
        <TextField
          label={showLabels ? 'City' : ''}
          value={cityValue || ''}
          variant="outlined"
          size="small"
          onChange={(e) => handleInputChange(`city${name}`, e.target.value)}
          error={!!cityValidationField}
          helperText={cityValidationField}
          required={required}
          InputProps={{
            readOnly: readOnly
          }}
          InputLabelProps={{
            sx: { fontSize: '12px' } // Change font size here
          }}
          {...props}
          sx={{
            '& .MuiInputBase-root': {
              height: 28
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CountyCityInput;
