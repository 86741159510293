import {
  AddSalesTax,
  IAddSalesTax,
  IRegisterClient,
  RegisterClient
} from 'src/interface/client';
// import axios from 'axios';
import fetchWrapper, { setNavigateCallback } from '../fetchWrapper';

// Set the navigate callback for the fetch wrapper
setNavigateCallback((path) => {
  console.log('Navigating to:', path);

  // Handle the navigation to the login page or logout
  localStorage.removeItem('user');
  // Perform the navigation
  // You may want to use a navigation library or window.location.href
});
export const registerClient = async (
  data: IRegisterClient
): Promise<RegisterClient> => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');

  // const response = await fetch(`/client/`, {

  const response = await fetchWrapper(`/client/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.api_token
    },
    body: JSON.stringify(data)
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.meta.message);
  }
  console.log({ response });

  return response.json();
};
export const addSalesTax = async (data: IAddSalesTax): Promise<AddSalesTax> => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');

  const response = await fetchWrapper(`/client/salesTax`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.api_token
    },
    body: JSON.stringify(data)
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.meta.message);
  }
  return response.json();
};

export const addBusinessTax = async (data: any) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');

  const response = await fetchWrapper(`/client/businessTax`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.api_token
    },
    body: JSON.stringify(data)
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.meta.message);
  }
  return response.json();
};
export const addPayroll = async (data: any) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');

  const response = await fetchWrapper(`/client/payroll`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.api_token
    },
    body: JSON.stringify(data)
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.meta.message);
  }
  return response.json();
};

export const addAdditionalNotes = async (data: any) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');

  const response = await fetchWrapper(`/client/additionalNotes`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.api_token
    },
    body: JSON.stringify(data)
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.meta.message);
  }
};

export const addOwnership = async (data: any) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  const response = await fetchWrapper(`/client/ownership`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.api_token
    },
    body: JSON.stringify(data)
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.meta.message);
  }
  return response.json();
};

// export const getAllClients = async () => {
//   const user = JSON.parse(localStorage.getItem('user') || 'null');
//   let response: any;
//   try {
//     response = await fetch(`/client/`, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: user.api_token
//       }
//     });
//   } catch (error) {
//     // Handle any errors here
//     console.error('Error fetching data:', error);
//   }

//   if (!response.ok) {
//     throw new Error('Failed to fetch data');
//   }

//   const data = await response.json();

//   return data;
// };
export const getAllClients = async () => {
  try {
    const response = await fetchWrapper('/client/', { method: 'GET' });

    if (response.status === 200) {
      const data = await response.json(); // Adjust based on your response structure
      console.log({ data });
      return data;
    } else {
      console.error('Unexpected status code:', response.status);
      throw new Error('Failed to fetch data');
    }
  } catch (error) {
    // Handle any errors here
    console.log('Error fetching clients:', error);
    throw new Error('Failed to fetch data');
  }
};
export const getSalesTaxActiveClients = async () => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  let response: any;
  try {
    response = await fetchWrapper(`/client/salesTax/active`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.api_token
      }
    });
  } catch (error) {
    // Handle any errors here
    console.error('Error fetching data:', error);
  }

  if (!response.ok) {
    throw new Error('Failed to fetch data');
  }

  const data = await response.json();

  return data;
};
export const getBusinessTaxClients = async () => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  let response: any;
  try {
    response = await fetchWrapper(`/client/businessTax/active`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.api_token
      }
    });
  } catch (error) {
    // Handle any errors here
    console.error('Error fetching data:', error);
  }

  if (!response.ok) {
    throw new Error('Failed to fetch data');
  }

  const data = await response.json();

  return data;
};
export const getPayrollClients = async () => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  let response: any;
  try {
    response = await fetchWrapper(`/client/payroll/active`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.api_token
      }
    });
  } catch (error) {
    // Handle any errors here
    console.error('Error fetching data:', error);
  }

  if (!response.ok) {
    throw new Error('Failed to fetch data');
  }

  const data = await response.json();

  return data;
};

export const getClientById = async (id: number) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  let response: any;
  try {
    response = await fetchWrapper(`/client/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.api_token
      }
    });
  } catch (error) {
    // Handle any errors here
    console.error('Error fetching data:', error);
  }

  if (!response.ok) {
    throw new Error('Failed to fetch data');
  }

  const data = await response.json();
  return data;
};

export const updateClient = async (id: number, data: any) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  let response: any;
  try {
    response = await fetchWrapper(`/client/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.api_token
      },
      body: JSON.stringify(data)
    });
  } catch (error) {
    // Handle any errors here
    console.error('Error fetching data:', error);
  }

  if (!response.ok) {
    throw new Error('Failed to fetch data');
  }

  const res = await response.json();
  return res;
};

// export const deleteClient = async (id: number) => {
//   const user = JSON.parse(localStorage.getItem('user') || 'null');
//   let response:any
//   try {
//      response = await fetch(`/client/${id}`, {
//       method: 'DELETE',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': user.api_token
//       },
//     });
// } catch (error) {
//   // Handle any errors here
//   console.error('Error fetching data:', error);
// }
// if (!response.ok) {
//   throw new Error('Failed to fetch data');
// }

// const data = await response.json();
// return data;
// }

export const getClientsWithSalesTaxActive = async (payload: {
  month: string;
  year: number;
}) => {
  try {
    const response = await fetchWrapper(
      `/reports/monthlySalesTax?month=${payload.month}&year=${payload.year}`,
      { method: 'GET' }
    );

    if (response.status === 200) {
      const data = await response.json(); // Adjust based on your response structure
      console.log({ data });
      return data;
    } else {
      console.error('Unexpected status code:', response.status);
      throw new Error('Failed to fetch data');
    }
  } catch (error) {
    // Handle any errors here
    console.log('Error fetching clients:', error);
    throw new Error('Failed to fetch data');
  }
};
