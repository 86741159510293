import fetchWrapper from 'src/fetchWrapper';

export const addBusinessTax = async (data: any) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');

  const response = await fetchWrapper(`/yearlyBusinessTaxes`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.api_token
    },
    body: JSON.stringify(data)
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.meta.message);
  }
  return response.json();
};

export const getAllBusinessTaxes = async (
  startDate: string = '',
  endDate: string = ''
) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  let response: any;
  try {
    response = await fetchWrapper(`/yearlyBusinessTaxes/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.api_token
      }
    });
  } catch (error) {
    // Handle any errors here
    console.error('Error fetching Sales:', error);
  }

  if (!response.ok) {
    throw new Error('Failed to fetch Sales');
  }

  const data = await response.json();

  return data;
};

export const deleteBusinessTax = async (id: number) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  let response: any;
  try {
    response = await fetchWrapper(`/yearlyBusinessTaxes/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.api_token
      }
    });
  } catch (error) {
    // Handle any errors here
    console.error('Error fetching Sales:', error);
  }
};
