import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  IconButton,
  TablePagination,
  Grid,
  TextField
} from '@mui/material';
import SearchBarWithCheckbox from '../HelperComponents/SearchBarWithCheckbox';

import { formatClientId } from 'src/utils/helperFunctions';
import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { NotificationManager } from 'react-notifications';
import ConfirmationDialogue from '../Dialog/confirmationDialogue';
import { BarLoader } from 'react-spinners';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';
import {
  deleteBusinessTax,
  getAllBusinessTaxes
} from 'src/services/businessTaxService';
import BusinessTaxForm from '../BusinessTaxForm/BusinessTaxForm';

const BusinessTax = () => {
  interface Column {
    id:
      | 'clientID'
      | 'state'
      | 'legalBusinessName'
      | 'doingBusinessAs'
      | 'monthYear'
      | 'taxPayble'
      | 'totalSales'
      | 'totalGasoline'
      | 'totalExempt'
      | 'totalHighTax'
      | 'totalLowTax'
      | 'Status'
      | 'Actions';
    label: string;
    minWidth?: number;
    align?: 'right';
    //   format?: (value: number) => string;
  }
  const columnsForSearchbar: Column[] = [
    { id: 'clientID', label: 'Client ID', minWidth: 300 },
    { id: 'state', label: 'State', minWidth: 150 },
    { id: 'legalBusinessName', label: 'Legal Business Name', minWidth: 200 },
    { id: 'doingBusinessAs', label: 'Doing Business As', minWidth: 150 },
    { id: 'monthYear', label: 'Month/Year', minWidth: 150 }
  ];

  const columns = [
    { id: 'clientID', label: 'Client ID' },
    { id: 'state', label: 'State' },
    { id: 'legalBusinessName', label: 'Legal Business Name', minWidth: 150 },
    { id: 'doingBusinessAs', label: 'Doing Business As', minWidth: 130 },
    { id: 'year', label: 'Year' },
    { id: 'countyTotalDeductions', label: 'County Total Deductions' },
    { id: 'cityTotalDeductions', label: 'City Total Deductions' },
    { id: 'totalSales', label: 'Total Sales' },
    { id: 'countyGrossTax', label: 'County Gross Tax' },
    { id: 'cityGrossTax', label: 'City Gross Tax' },
    { id: 'countyNetTax', label: 'County Net Tax' },
    { id: 'cityNetTax', label: 'City Net Tax' },
    { id: ' totalBusinessTax', label: 'Total Business Tax' },
    { id: 'Actions', label: 'Actions' }
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [businessTaxFormAction, setBusinessTaxFormAction] = useState('');
  const [loader, setLoader] = useState(false);
  const [businessTaxes, setBusinessTaxes] = useState([]);
  const [selectedBusinessTaxes, setSelectedBusinessTaxes] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [isLoading, setIsLoading] = useState(false);
  const [isViewBusinessTaxModal, setIsViewPayrolModal] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [unFilteredBusinessTaxes, setUnfilteredBusinessTaxes] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedStartMonth, setSelectedStartMonth] = useState(null);
  const [selectedEndMonth, setSelectedEndMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    'Legal Business Name',
    'Doing Business As'
  ]);
  const [searchValue, setSearchValue] = useState<string>('');
  const openModal = () => {
    setIsModalOpen(true);
  };

  const styles = {
    cell: {
      border: '1px solid #E3E1D9',
      borderCollapse: 'collapse',
      padding: '6px'
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    getBusinessTaxes();
  };

  const getBusinessTaxes = async (startDate?: string, endDate?: string) => {
    setLoader(true);

    try {
      const businessTaxResponse = await getAllBusinessTaxes();

      if (businessTaxResponse && businessTaxResponse.data) {
        const sortedResponse = sortByYear(businessTaxResponse.data);
        setBusinessTaxes(sortedResponse);
        setUnfilteredBusinessTaxes(businessTaxResponse.data);
        // handleFilter(selectedColumns, searchValue);
      }
    } catch (error) {
      console.error('Error fetching payroll data:', error);
    } finally {
      setLoader(false);
    }
  };

  const user = JSON.parse(localStorage.getItem('user') || 'null');

  const sortByYear = (data: any) => {
    return data.sort((a, b) => {
      return a.year === b.year ? 0 : a.year > b.year ? 1 : -1;
    });
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getBusinessTaxes();
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };
    fetchData();
  }, []);
  function handleBusinessTaxView(index: number): void {}

  const closeConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const handleDelete = async (index: number) => {
    setSelectedBusinessTaxes(businessTaxes[index]);
    setOpenConfirmationDialog(true);
  };
  const deleteBusinessTaxes = async () => {
    try {
      setIsLoading(true);
      setOpenConfirmationDialog(true);
      await deleteBusinessTax(selectedBusinessTaxes.id);
      NotificationManager.success('Successfully deleted Business Tax');
      closeConfirmationDialog();
      getBusinessTaxes();
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  //sort and search

  const sortTableDataByColumnsAndSearch = (
    data: any,
    selectedColumns: string[],
    searchValue: string
  ) => {
    if (searchValue.trim() === '' || !selectedColumns.length) {
      // Return all data if searchValue is empty
      return data;
    }

    const filteredData = data.filter((item: any) => {
      let isMatch = false;
      searchValue = searchValue.toLocaleLowerCase();
      selectedColumns.forEach((column) => {
        switch (column) {
          case 'Client ID':
            if (item.client.clientId?.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          case 'State':
            if (item.client.state?.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          case 'Legal Business Name':
            if (
              item.client.legalBusinessName?.toLowerCase().includes(searchValue)
            ) {
              isMatch = true;
            }
            break;
          case 'Doing Business As':
            if (
              item.client.doingBusinessAs?.toLowerCase().includes(searchValue)
            ) {
              isMatch = true;
            }
            break;
          case 'Federal Tax ID':
            if (item.client.federalTaxId?.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          case 'Contact Person':
            if (
              item.client.contactPerson?.toLowerCase().includes(searchValue)
            ) {
              isMatch = true;
            }
            break;
          case 'Contact Email':
            if (item.client.contactEmail?.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          case 'Contact Number':
            if (
              item.client.contactNumber?.toLowerCase().includes(searchValue)
            ) {
              isMatch = true;
            }
            break;
          case 'Accountant':
            if (
              item.client.accountantName?.toLowerCase().includes(searchValue)
            ) {
              isMatch = true;
            }
            break;
          // Add cases for other columns as needed
          default:
            break;
        }
      });

      return isMatch;
    });

    return filteredData;
  };

  const handleFilter = (selectedColumns: string[], searchValue: string) => {
    let businessTaxes = unFilteredBusinessTaxes || [];
    console.log(businessTaxes);
    if (selectedYear) {
      const yearOnly = new Date(selectedYear).getFullYear();
      businessTaxes = businessTaxes.filter(
        (item) =>
          // console.log(item.year, yearOnly)
          item.year === yearOnly
      );
    }
    if (searchValue === '') {
      setBusinessTaxes(businessTaxes);
      return;
    }
    const filteredData = sortTableDataByColumnsAndSearch(
      businessTaxes,
      selectedColumns,
      searchValue
    );
    setBusinessTaxes(filteredData);
  };

  const handleExportToExcel = async () => {};
  const handleClearFilter = async () => {
    try {
      getBusinessTaxes();
      setSelectedYear(null);
      setSearchValue('');
      setSelectedColumns(['Legal Business Name', 'Doing Business As']);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (searchValue === '') {
      setBusinessTaxes(unFilteredBusinessTaxes);
    }
  }, [searchValue]);

  useEffect(() => {
    handleFilter(selectedColumns, searchValue);
  }, [selectedYear]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        await getBusinessTaxes();
      } catch (error) {
        console.error('Error fetching data:', error);
        NotificationManager.error(
          `Error fetching data:, ${error}`,
          'Error',
          3000
        );
      }
    };

    fetchData(); // Call the function to fetch data when component mounts
  }, []);
  return (
    <Box sx={{ mt: 1, mx: '1vw' }}>
      {loader && (
        <div className="loader-wrapper" style={{ textAlign: 'center' }}>
          <BarLoader color={'#1976D2'} loading={loader} width={'96vw'} />
        </div>
      )}
      <Box display={'flex'} justifyContent={'center'} width={'100vw'}>
        <Typography variant="h4" color={'#1976D2'}>
          BUSINESS TAX
        </Typography>
      </Box>
      <Grid container mt={2}>
        <Grid item xs={12} md={4}>
          <Box
            display="flex"
            justifyContent="flex-start"
            // width={{ xs: '56', sm: '42vw', md: '42vw' }}
            // marginTop={'15px'}
            marginRight={'5px'}
          >
            <SearchBarWithCheckbox
              columns={columnsForSearchbar}
              onSearch={handleFilter}
              value={searchValue}
              scolumns={selectedColumns}
              updateSearchValue={setSearchValue}
              updateSelectedColumns={setSelectedColumns}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4} sx={{ ml: { md: 10 }, mt: { md: 0, xs: 2 } }}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4} mt={0}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box sx={{ width: '100%' }}>
                  <DatePicker
                    label="Year"
                    views={['year']}
                    openTo="year"
                    value={selectedYear}
                    inputFormat="yyyy"
                    onChange={setSelectedYear}
                    renderInput={(props) => (
                      <TextField {...props} style={{ width: '100%' }} />
                    )}
                    InputProps={{
                      size: 'small',
                      name: 'year'
                    }}
                  />
                </Box>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} md={1} sx={{ mt: { md: 0, xs: 2 } }}>
          <Grid container display="flex" justifyContent="space-between">
            <Grid item xs={3.5} md={4}>
              <Button
                variant="contained"
                color="warning"
                onClick={handleClearFilter}
                sx={{ scale: { xs: '0.8', md: '1' } }}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8} md={2} sx={{ mt: { md: 0, xs: 2 }, ml: { xs: 3 } }}>
          <Grid container display="flex" justifyContent="space-between">
            <Grid item xs={4} md={5}>
              <Button
                variant="contained"
                color="success"
                onClick={handleExportToExcel}
                sx={{ scale: { xs: '0.8', md: '1' } }}
              >
                Export
                <FileDownloadIcon />
              </Button>
            </Grid>
            <Grid item xs={8} md={7}>
              <Button
                variant="contained"
                sx={{
                  padding: '10px',
                  scale: '0.9',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  '&:hover': {
                    transform: 'scale(0.9)',
                    transition: 'all 0.3s ease'
                  },
                  ml: { xs: 5 }
                  // width: '100px', // Fixed width for the button // Aligns the button to the end
                }}
                onClick={() => {
                  setIsModalOpen(true);
                  setBusinessTaxFormAction('add');
                }}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box mt={3}>
        <Paper sx={{ width: '96vw', overflow: 'hidden' }} elevation={3}>
          <TableContainer sx={{ width: '100%', overflow: 'auto' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: '800',
                        color: '#1976D2',
                        border: '1px solid #E3E1D9',
                        borderCollapse: 'collapse',
                        padding: 5
                      }}
                    >
                      <TableSortLabel>{column.label}</TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {businessTaxes
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell sx={styles.cell}>
                        {formatClientId(+row.clientId)}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {row.client && row.client.state}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {row.client && row.client.legalBusinessName}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {row.client && row.client.doingBusinessAs}
                      </TableCell>
                      <TableCell sx={styles.cell}>{`${row.year}`}</TableCell>
                      <TableCell
                        sx={styles.cell}
                      >{`${row.countyTotalDeductions}`}</TableCell>
                      <TableCell
                        sx={styles.cell}
                      >{`${row.cityTotalDeductions}`}</TableCell>
                      <TableCell sx={styles.cell}>{row.totalSales}</TableCell>
                      <TableCell sx={styles.cell}>
                        {row.countyGrossTax}
                      </TableCell>
                      <TableCell sx={styles.cell}>{row.cityGrossTax}</TableCell>
                      <TableCell sx={styles.cell}>{row.countyNetTax}</TableCell>
                      <TableCell sx={styles.cell}>{row.cityNetTax}</TableCell>
                      <TableCell sx={styles.cell}>
                        {row.totalBusinessTax}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          // marginTop: '20px',
                          ...styles.cell
                        }}
                      >
                        <IconButton
                          // onClick={() => handleEdit(index)}
                          style={{ color: '#1976D2', cursor: 'pointer' }}
                        >
                          <VisibilityIcon
                            // onClick={() => handlePayrollView(index)}
                            style={{ color: '#1976D2', cursor: 'pointer' }}
                          />
                        </IconButton>
                        {user.role == 'admin' && (
                          <IconButton
                            // onClick={() => handleEdit(index)}
                            style={{ color: '#1976D2', cursor: 'pointer' }}
                          >
                            <DeleteIcon
                              onClick={() => handleDelete(index)}
                              style={{ color: '#1976D2', cursor: 'pointer' }}
                            />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[50, 100, 200]}
            count={businessTaxes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <BusinessTaxForm
          open={isModalOpen}
          onClose={closeModal}
          businessTax={selectedBusinessTaxes}
          action={businessTaxFormAction}
        />
      </Box>
      <ConfirmationDialogue
        open={openConfirmationDialog}
        close={closeConfirmationDialog}
        confirmationMessage="Are you sure you want to delete this business tax?"
        onConfirm={deleteBusinessTaxes}
      />
    </Box>
  );
};

export default BusinessTax;
