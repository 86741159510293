import React from 'react';
import { Grid, InputAdornment, InputLabel, TextField } from '@mui/material';

const TextInput = ({
  value,
  label = null,
  handleInputChange,
  validationField,
  name,
  placeholder = '',
  required = false,
  readOnly = false,
  props = {},
}) => {
  return (
    <Grid item xs={12} md={4}>
      {label && (
        <InputLabel htmlFor={name}>
          {label}
          {required && <span style={{ color: 'red' }}>*</span>}
        </InputLabel>
      )}
      <TextField
        id={name}
        placeholder={placeholder || ''}
        variant="outlined"
        fullWidth
        size="small"
        value={value}
        onChange={(e) => handleInputChange(name, e.target.value)}
        error={!!validationField}
        helperText={validationField}
        InputProps={{
          readOnly: readOnly,
        }}
        {...props}
      />
    </Grid>
  );
};

export default TextInput;
