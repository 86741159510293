import React, { useEffect, useMemo, useState } from 'react';
import { addBusinessTax } from 'src/services/businessTaxService';
import { NotificationManager } from 'react-notifications';
import {
  Typography,
  Box,
  Modal,
  Grid,
  TextField,
  InputLabel,
  Button,
  Autocomplete,
  InputAdornment,
  ButtonGroup,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody
} from '@mui/material';
import { BarLoader } from 'react-spinners';
import Dropdown from '../ClientsForm/dropdown';

import { Close as CloseIcon } from '@mui/icons-material';
import {
  getClientById,
  getBusinessTaxClients
} from 'src/services/clientService';
import { validateYearlyBusinessTaxes } from 'src/validations/businessTaxForm';
import CountyCityInput from './CountyCityInput';
import { number } from 'prop-types';
import { getSaleTaxReport } from 'src/services/salesService';
import moment from 'moment';

export interface ClientData {
  id: number;
  clientId: string;
  federalTaxId: string;
  businessStructure: string;
  taxClassification: string;
  legalBusinessName: string;
  doingBusinessAs: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  county: string;
  state: string;
  zipCode: string;
  mailingAddressSameAsLocation: boolean;
  mailingStreetAddress1: string;
  mailingStreetAddress2: string;
  mailingCity: string;
  mailingCounty: string;
  mailingState: string;
  mailingZipCode: string;
  registeredAgent: string;
  contactPerson: string;
  contactNumber: string;
  contactEmail: string;
  contactFax: string;
  property: boolean;
  salesTax: boolean;
  businessTax: boolean;
  franchiseTax: boolean;
  payroll: boolean;
  annualReport: boolean;
  bookKeeping: boolean;
  taxReturnPreparation: boolean;
  tppReturnPreparation: boolean;
  annualReportDueDate: string;
  taxReturnDueDate: string;
  tppReturnDueDate: string;
  inactive: boolean;
  reasonForInactive: string;
  otherReason: string | null;
  accountantName: string;
  industry: string;
  otherIndustry: string | null;
  franchiseTaxNumber: string;
  sosNumber: string;
  businessFormationDate: string;
  monthlyFees: number | null;
  monthlyFeesStartDate: string;
  inactiveDate: string;
  additionalNotes: string;
  createdAt: string;
  updatedAt: string;

  salesTaxes: {
    id: number;
    clientId: string;
    salesTaxId: string;
    routingNumber: string;
    accountNumber: string;
    stateHighTax: string;
    stateLowTax: string;
    cityCountyTax: string;
    countyTax: string;
    cityTax: string;
    liquorTax: string;
    beerTax: string;
    cbdTax: string;
    specialTax: string;
    fees911: string;
    discountRate: string;
    importantNotes: string;
    contactName: string;
    contactNumber: string;
    contactEmail: string;
    salesStartDate: string;
    salesStopDate: string;
    createdAt: string;
    updatedAt: string;
  };

  businessTaxes: {
    id: number;
    clientId: number;
    businessTaxId: string;
    locationId: string;
    businessType: string;
    classification: string;
    taxRate: string;
    gasolineTaxPerGallonFederal: string;
    gasolineTaxPerGallonState: string;
    dieselTaxPerGallonFederal: string;
    dieselTaxPerGallonState: string;
    avgGasolineTaxPerGallonFederal: string;
    avgGasolineTaxPerGallonState: string;
    tobaccoTaxPackOf20Federal: string;
    tobaccoTaxPackOf20State: string;
    tobaccoTaxOnOtpOfWholesaleCostFederal: string;
    tobaccoTaxOnOtpOfWholesaleCostState: string;
    tobaccoTaxPerLargeCigarFederal: string;
    tobaccoTaxPerLargeCigarState: string;
    tobaccoTaxOnPipePerPoundFederal: string;
    tobaccoTaxOnPipePerPoundState: string;
    tobaccoTaxOnRyoPerPoundFederal: string;
    tobaccoTaxOnRyoPerPoundState: string;
    beerTaxPerGallonFederal: string;
    beerTaxPerGallonState: string;
    createdAt: string;
    updatedAt: string;
  };

  payrolls: {
    id: number;
    clientId: number;
    eftpsPin: string;
    withHoldingNumber: string;
    laborAccNumber: string;
    payrollSchedule: string;
    payrollDeliveryMethod: string;
    payrollProcessingDay: string;
    importantNotes: string;
    contactName: string;
    contactNumber: string;
    contactEmail: string;
    payrollStartDate: string;
    payrollStopDate: string;
    createdAt: string;
    updatedAt: string;
  };

  ownerships: any[];
}

const BusinessTaxForm = ({ open, onClose, businessTax, action }) => {
  const styles = {
    cell: {
      border: '1px solid #E3E1D9',
      borderCollapse: 'collapse',
      padding: '6px'
    }
  };
  const monthArray = getMonths();
  const yearsArray = getYears(new Date().getFullYear(), 1900);
  const [clients, setClients] = useState([]);
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 1); // Move to the previous month
  const previousMonth = currentDate.toLocaleString('default', {
    month: 'long'
  });
  const previousYear = currentDate.getFullYear();
  // const [clientData, setClientData] = useState({ businessTaxes: {} });
  // const [clientData, setClientData] = useState(null)
  const [clientData, setClientData] = useState<ClientData | null>(null);
  // const [clientSalesData, setClientSalesData] = useState({})
  const [clientSalesData, setClientSalesData] = useState<{ data: any[] }>({
    data: []
  });

  const [businessTaxes, setBusinessTaxes]: any = useState({
    client: {},
    year: previousYear,

    clientId: '',
    totalSales: '',
    cityPropertyTax: '',
    countyPropertyTax: '',

    citySalesServiceReceivedByPersonsLocatedInOtherStates: '',
    countySalesServiceReceivedByPersonsLocatedInOtherStates: '',
    cityReturnedMerchandiseSalePriceRefundedToCustomer: '',
    countyReturnedMerchandiseSalePriceRefundedToCustomer: '',
    citySalesTangiblePersonalPropertyInInterstateCommerce: '',
    countySalesTangiblePersonalPropertyInInterstateCommerce: '',
    cityCashDiscountsAllowedAndTakenOnSales: '',
    countyCashDiscountsAllowedAndTakenOnSales: '',
    cityBadDebtsWrittenDuringReportingPeriod: '',
    countyBadDebtsWrittenDuringReportingPeriod: '',
    cityFederalAndTennesseeGasolineTax: '',
    countyFederalAndTennesseeGasolineTax: '',
    cityFederalAndTennesseeMotorFuelTax: '',
    countyFederalAndTennesseeMotorFuelTax: '',
    cityFederalAndTennesseeTobaccoTaxOnCigarettes: '',
    countyFederalAndTennesseeTobaccoTaxOnCigarettes: '',
    cityFederalAndTennesseeTobaccoTaxOnOtherTobaccoProducts: '',
    countyFederalAndTennesseeTobaccoTaxOnOtherTobaccoProducts: '',
    cityFederalAndTennesseeBeerTax: '',
    countyFederalAndTennesseeBeerTax: '',
    cityTennesseeSpecialTaxOnPetroleumProducts: '',
    countyTennesseeSpecialTaxOnPetroleumProducts: '',
    cityTennesseeLiquifiedGasTaxForCertainMotorVehicles: '',
    countyTennesseeLiquifiedGasTaxForCertainMotorVehicles: '',
    cityTennesseeBeerWholesaleTax: '',
    countyTennesseeBeerWholesaleTax: '',
    cityTennesseeBailBondsTax: '',
    countyTennesseeBailBondsTax: '',
    cityOtherDeductionsNotTakenElsewhereOnReturn: '',
    countyOtherDeductionsNotTakenElsewhereOnReturn: '',

    cityGrossSales: '',
    countyGrossSales: '',

    countyTaxableGrossStateSales: '',
    cityTaxableGrossStateSales: '',
    taxRate: '',
    countyTotalDeductions: '',
    cityTotalDeductions: '',
    countyGrossTax: '',
    cityGrossTax: '',
    countyPersonalPropertyTaxCredit: '',
    cityPersonalPropertyTaxCredit: '',
    countyNetTax: '',
    cityNetTax: '',
    totalBusinessTax: ''
  });

  const deductionFieldKeys = [
    'SalesServiceReceivedByPersonsLocatedInOtherStates',
    'ReturnedMerchandiseSalePriceRefundedToCustomer',
    'SalesTangiblePersonalPropertyInInterstateCommerce',
    'CashDiscountsAllowedAndTakenOnSales',
    'BadDebtsWrittenDuringReportingPeriod',
    'FederalAndTennesseeGasolineTax',
    'FederalAndTennesseeMotorFuelTax',
    'FederalAndTennesseeTobaccoTaxOnCigarettes',
    'FederalAndTennesseeTobaccoTaxOnOtherTobaccoProducts',
    'FederalAndTennesseeBeerTax',
    'TennesseeSpecialTaxOnPetroleumProducts',
    'TennesseeLiquifiedGasTaxForCertainMotorVehicles',
    'TennesseeBeerWholesaleTax',
    'TennesseeBailBondsTax',
    'OtherDeductionsNotTakenElsewhereOnReturn'
  ];

  const deductionFieldLabels = [
    'Sales or services received by persons located in other states',
    'Returned merchandise when the sales price is refunded to the customer',
    'Sales of tangible personal property in interstate commerce',
    'Cash discounts allowed and taken on sales',
    'Bad debts written off during the reporting period',
    'Federal and Tennessee gasoline tax',
    'Federal and Tennessee motor fuel tax',
    'Federal and Tennessee tobacco tax on cigarettes',
    'Federal and Tennessee tobacco tax on all other tobacco products',
    'Federal and Tennessee beer tax',
    'Tennessee special tax on petroleum products',
    'Tennessee liquified gas tax for certain motor vehicles',
    'Tennessee beer wholesale tax',
    'Tennessee bail bonds tax',
    'Other deductions not taken elsewhere on the return'
  ];
  const getClients = async () => {
    try {
      const data = await getBusinessTaxClients();
      setClients(data.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors]: any = useState({});

  const validateFields = async () => {
    let errors = {};
    const basicDataErrors = await validateYearlyBusinessTaxes(businessTaxes);
    errors = { ...errors, ...basicDataErrors };
    await setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const resetBusinessTaxData = () => {
    setBusinessTaxes({
      client: {},
      year: previousYear,

      clientId: '',
      totalSales: '',
      cityPropertyTax: '',
      countyPropertyTax: '',

      citySalesServiceReceivedByPersonsLocatedInOtherStates: '',
      countySalesServiceReceivedByPersonsLocatedInOtherStates: '',
      cityReturnedMerchandiseSalePriceRefundedToCustomer: '',
      countyReturnedMerchandiseSalePriceRefundedToCustomer: '',
      citySalesTangiblePersonalPropertyInInterstateCommerce: '',
      countySalesTangiblePersonalPropertyInInterstateCommerce: '',
      cityCashDiscountsAllowedAndTakenOnSales: '',
      countyCashDiscountsAllowedAndTakenOnSales: '',
      cityBadDebtsWrittenDuringReportingPeriod: '',
      countyBadDebtsWrittenDuringReportingPeriod: '',
      cityFederalAndTennesseeGasolineTax: '',
      countyFederalAndTennesseeGasolineTax: '',
      cityFederalAndTennesseeMotorFuelTax: '',
      countyFederalAndTennesseeMotorFuelTax: '',
      cityFederalAndTennesseeTobaccoTaxOnCigarettes: '',
      countyFederalAndTennesseeTobaccoTaxOnCigarettes: '',
      cityFederalAndTennesseeTobaccoTaxOnOtherTobaccoProducts: '',
      countyFederalAndTennesseeTobaccoTaxOnOtherTobaccoProducts: '',
      cityFederalAndTennesseeBeerTax: '',
      countyFederalAndTennesseeBeerTax: '',
      cityTennesseeSpecialTaxOnPetroleumProducts: '',
      countyTennesseeSpecialTaxOnPetroleumProducts: '',
      cityTennesseeLiquifiedGasTaxForCertainMotorVehicles: '',
      countyTennesseeLiquifiedGasTaxForCertainMotorVehicles: '',
      cityTennesseeBeerWholesaleTax: '',
      countyTennesseeBeerWholesaleTax: '',
      cityTennesseeBailBondsTax: '',
      countyTennesseeBailBondsTax: '',
      cityOtherDeductionsNotTakenElsewhereOnReturn: '',
      countyOtherDeductionsNotTakenElsewhereOnReturn: '',

      cityGrossSales: '',
      countyGrossSales: '',

      countyTaxableGrossStateSales: '',
      cityTaxableGrossStateSales: '',
      taxRate: '',
      countyTotalDeductions: '',
      cityTotalDeductions: '',
      countyGrossTax: '',
      cityGrossTax: '',
      countyPersonalPropertyTaxCredit: '',
      cityPersonalPropertyTaxCredit: '',
      countyNetTax: '',
      cityNetTax: '',
      totalBusinessTax: ''
    });
  };

  const resetClose = () => {
    resetBusinessTaxData();
    onClose();
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      setLoading(true);
      const isValid = await validateFields();

      if (isValid) {
        const data = {
          clientId: businessTaxes.client.id,
          year: businessTaxes.year,
          totalSales: removeCommasAndConvertToFloat(businessTaxes.totalSales),
          cityPropertyTax: removeCommasAndConvertToFloat(
            businessTaxes.cityPropertyTax
          ),
          countyPropertyTax: removeCommasAndConvertToFloat(
            businessTaxes.countyPropertyTax
          ),

          citySalesServiceReceivedByPersonsLocatedInOtherStates:
            removeCommasAndConvertToFloat(
              businessTaxes.citySalesServiceReceivedByPersonsLocatedInOtherStates
            ),
          countySalesServiceReceivedByPersonsLocatedInOtherStates:
            removeCommasAndConvertToFloat(
              businessTaxes.countySalesServiceReceivedByPersonsLocatedInOtherStates
            ),
          cityReturnedMerchandiseSalePriceRefundedToCustomer:
            removeCommasAndConvertToFloat(
              businessTaxes.cityReturnedMerchandiseSalePriceRefundedToCustomer
            ),
          countyReturnedMerchandiseSalePriceRefundedToCustomer:
            removeCommasAndConvertToFloat(
              businessTaxes.countyReturnedMerchandiseSalePriceRefundedToCustomer
            ),
          citySalesTangiblePersonalPropertyInInterstateCommerce:
            removeCommasAndConvertToFloat(
              businessTaxes.citySalesTangiblePersonalPropertyInInterstateCommerce
            ),
          countySalesTangiblePersonalPropertyInInterstateCommerce:
            removeCommasAndConvertToFloat(
              businessTaxes.countySalesTangiblePersonalPropertyInInterstateCommerce
            ),
          cityCashDiscountsAllowedAndTakenOnSales:
            removeCommasAndConvertToFloat(
              businessTaxes.cityCashDiscountsAllowedAndTakenOnSales
            ),
          countyCashDiscountsAllowedAndTakenOnSales:
            removeCommasAndConvertToFloat(
              businessTaxes.countyCashDiscountsAllowedAndTakenOnSales
            ),
          cityBadDebtsWrittenDuringReportingPeriod:
            removeCommasAndConvertToFloat(
              businessTaxes.cityBadDebtsWrittenDuringReportingPeriod
            ),
          countyBadDebtsWrittenDuringReportingPeriod:
            removeCommasAndConvertToFloat(
              businessTaxes.countyBadDebtsWrittenDuringReportingPeriod
            ),
          cityFederalAndTennesseeGasolineTax: removeCommasAndConvertToFloat(
            businessTaxes.cityFederalAndTennesseeGasolineTax
          ),
          countyFederalAndTennesseeGasolineTax: removeCommasAndConvertToFloat(
            businessTaxes.countyFederalAndTennesseeGasolineTax
          ),
          cityFederalAndTennesseeMotorFuelTax: removeCommasAndConvertToFloat(
            businessTaxes.cityFederalAndTennesseeMotorFuelTax
          ),
          countyFederalAndTennesseeMotorFuelTax: removeCommasAndConvertToFloat(
            businessTaxes.countyFederalAndTennesseeMotorFuelTax
          ),
          cityFederalAndTennesseeTobaccoTaxOnCigarettes:
            removeCommasAndConvertToFloat(
              businessTaxes.cityFederalAndTennesseeTobaccoTaxOnCigarettes
            ),
          countyFederalAndTennesseeTobaccoTaxOnCigarettes:
            removeCommasAndConvertToFloat(
              businessTaxes.countyFederalAndTennesseeTobaccoTaxOnCigarettes
            ),
          cityFederalAndTennesseeTobaccoTaxOnOtherTobaccoProducts:
            removeCommasAndConvertToFloat(
              businessTaxes.cityFederalAndTennesseeTobaccoTaxOnOtherTobaccoProducts
            ),
          countyFederalAndTennesseeTobaccoTaxOnOtherTobaccoProducts:
            removeCommasAndConvertToFloat(
              businessTaxes.countyFederalAndTennesseeTobaccoTaxOnOtherTobaccoProducts
            ),
          cityFederalAndTennesseeBeerTax: removeCommasAndConvertToFloat(
            businessTaxes.cityFederalAndTennesseeBeerTax
          ),
          countyFederalAndTennesseeBeerTax: removeCommasAndConvertToFloat(
            businessTaxes.countyFederalAndTennesseeBeerTax
          ),
          cityTennesseeSpecialTaxOnPetroleumProducts:
            removeCommasAndConvertToFloat(
              businessTaxes.cityTennesseeSpecialTaxOnPetroleumProducts
            ),
          countyTennesseeSpecialTaxOnPetroleumProducts:
            removeCommasAndConvertToFloat(
              businessTaxes.countyTennesseeSpecialTaxOnPetroleumProducts
            ),
          cityTennesseeLiquifiedGasTaxForCertainMotorVehicles:
            removeCommasAndConvertToFloat(
              businessTaxes.cityTennesseeLiquifiedGasTaxForCertainMotorVehicles
            ),
          countyTennesseeLiquifiedGasTaxForCertainMotorVehicles:
            removeCommasAndConvertToFloat(
              businessTaxes.countyTennesseeLiquifiedGasTaxForCertainMotorVehicles
            ),
          cityTennesseeBeerWholesaleTax: removeCommasAndConvertToFloat(
            businessTaxes.cityTennesseeBeerWholesaleTax
          ),
          countyTennesseeBeerWholesaleTax: removeCommasAndConvertToFloat(
            businessTaxes.countyTennesseeBeerWholesaleTax
          ),
          cityTennesseeBailBondsTax: removeCommasAndConvertToFloat(
            businessTaxes.cityTennesseeBailBondsTax
          ),
          countyTennesseeBailBondsTax: removeCommasAndConvertToFloat(
            businessTaxes.countyTennesseeBailBondsTax
          ),
          cityOtherDeductionsNotTakenElsewhereOnReturn:
            removeCommasAndConvertToFloat(
              businessTaxes.cityOtherDeductionsNotTakenElsewhereOnReturn
            ),
          countyOtherDeductionsNotTakenElsewhereOnReturn:
            removeCommasAndConvertToFloat(
              businessTaxes.countyOtherDeductionsNotTakenElsewhereOnReturn
            ),

          countyTotalDeductions: removeCommasAndConvertToFloat(
            businessTaxes.countyTotalDeductions
          ),
          cityTotalDeductions: removeCommasAndConvertToFloat(
            businessTaxes.cityTotalDeductions
          ),
          countyGrossTax: removeCommasAndConvertToFloat(
            businessTaxes.countyGrossTax
          ),
          cityGrossTax: removeCommasAndConvertToFloat(
            businessTaxes.cityGrossTax
          ),
          countyNetTax: removeCommasAndConvertToFloat(
            businessTaxes.countyNetTax
          ),
          cityNetTax: removeCommasAndConvertToFloat(businessTaxes.cityNetTax),
          totalBusinessTax: removeCommasAndConvertToFloat(
            businessTaxes.totalBusinessTax
          )
        };

        try {
          const response: any = await addBusinessTax(data);
          if (response.meta.status === 200) {
            NotificationManager.success(
              'Business tax record added successfully',
              'Success',
              3000
            );
            return response;
          }
        } catch (error) {
          NotificationManager.error(error.message, 'Error', 3000);
          return false;
        }
      } else {
        NotificationManager.error('Validation Failed!!', 'Error', 3000);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      await getClients();
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (businessTaxes?.client && typeof businessTaxes.client !== 'object') {
      handleInputChange('client', businessTaxes.client);
    }
  }, [businessTaxes?.client]);

  useEffect(() => {
    setBusinessTaxes((prevData) => ({
      ...prevData,
      totalSales: removeCommasAndConvertToFloat(totalSales.toLocaleString()),
      cityGrossSales: removeCommasAndConvertToFloat(
        totalSales.toLocaleString()
      ),
      countyGrossSales: removeCommasAndConvertToFloat(
        totalSales.toLocaleString()
      )
    }));
  }, [businessTaxes.year, businessTaxes.totalSales, businessTaxes.client]); // Runs whenever `businessTaxes` changes
  useEffect(() => {
    if (clientData) {
      setBusinessTaxes((prevData) => ({
        ...prevData,
        taxRate: removeCommasAndConvertToFloat(clientData.businessTaxes.taxRate)
      }));
    }
  }, [clientData]);

  const reportingPeriodDate = useMemo(() => {
    if (!businessTaxes.year) return ''; // Handle case where year is not set
    const reportingYear = parseInt(businessTaxes.year) + 1; // Next year
    return `15 April ${reportingYear}`;
  }, [businessTaxes.year]);

  const handleInputChange = async (field, value) => {
    const regex = /^[0-9]*(\.[0-9]{0,1})?$/;
    if (field === 'year' && businessTaxes.client?.id) {
      const selectedClientSales = await getSaleTaxReport({
        clientId: businessTaxes.client.id,
        monthStart: `${value}-01`,
        monthEnd: `${value}-12`
      });

      setClientSalesData(selectedClientSales);
    }

    // Refresh client data if client changes
    if (field === 'client') {
      const selectedClient = await getClientById(value.id);
      setClientData(selectedClient.data);

      const selectedClientSales = await getSaleTaxReport({
        clientId: value.id || 0,
        monthStart: `${businessTaxes.year}-01`,
        monthEnd: `${businessTaxes.year}-12`
      });

      setClientSalesData(selectedClientSales);
    }

    setBusinessTaxes((prevData) => {
      let updatedData: any = {};

      if (regex.test(value)) {
        updatedData = {
          ...prevData,
          [field]: `${value}`
        };
      } else {
        updatedData = {
          ...prevData,
          [field]: value
        };
      }

      // Preserve taxRate if not being updated
      updatedData.taxRate =
        field === 'taxRate'
          ? removeCommasAndConvertToFloat(value)
          : removeCommasAndConvertToFloat(prevData.taxRate || '0');

      // Calculate deductions
      updatedData.countyTotalDeductions =
        removeCommasAndConvertToFloat(
          updatedData.countySalesServiceReceivedByPersonsLocatedInOtherStates
        ) +
        removeCommasAndConvertToFloat(
          updatedData.countyReturnedMerchandiseSalePriceRefundedToCustomer
        ) +
        removeCommasAndConvertToFloat(
          updatedData.countySalesTangiblePersonalPropertyInInterstateCommerce
        ) +
        removeCommasAndConvertToFloat(
          updatedData.countyCashDiscountsAllowedAndTakenOnSales
        ) +
        removeCommasAndConvertToFloat(
          updatedData.countyBadDebtsWrittenDuringReportingPeriod
        ) +
        removeCommasAndConvertToFloat(
          updatedData.countyFederalAndTennesseeGasolineTax
        ) +
        removeCommasAndConvertToFloat(
          updatedData.countyFederalAndTennesseeMotorFuelTax
        ) +
        removeCommasAndConvertToFloat(
          updatedData.countyFederalAndTennesseeTobaccoTaxOnAllOtherTobaccoProducts
        ) +
        removeCommasAndConvertToFloat(
          updatedData.countyFederalAndTennesseeTobaccoTaxOnCigarettes
        ) +
        removeCommasAndConvertToFloat(
          updatedData.countyFederalAndTennesseeBeerTax
        ) +
        removeCommasAndConvertToFloat(
          updatedData.countyTennesseeSpecialTaxOnPetroleumProducts
        ) +
        removeCommasAndConvertToFloat(
          updatedData.countyTennesseeLiquifiedGasTaxForCertainMotorVehicles
        ) +
        removeCommasAndConvertToFloat(
          updatedData.countyTennesseeBeerWholesaleTax
        ) +
        removeCommasAndConvertToFloat(updatedData.countyTennesseeBailBondsTax) +
        removeCommasAndConvertToFloat(
          updatedData.countyOtherDeductionsNotTakenElsewhereOnReturn
        );

      updatedData.cityTotalDeductions =
        removeCommasAndConvertToFloat(
          updatedData.citySalesServiceReceivedByPersonsLocatedInOtherStates
        ) +
        removeCommasAndConvertToFloat(
          updatedData.cityReturnedMerchandiseSalePriceRefundedToCustomer
        ) +
        removeCommasAndConvertToFloat(
          updatedData.citySalesTangiblePersonalPropertyInInterstateCommerce
        ) +
        removeCommasAndConvertToFloat(
          updatedData.cityCashDiscountsAllowedAndTakenOnSales
        ) +
        removeCommasAndConvertToFloat(
          updatedData.cityBadDebtsWrittenDuringReportingPeriod
        ) +
        removeCommasAndConvertToFloat(
          updatedData.cityFederalAndTennesseeGasolineTax
        ) +
        removeCommasAndConvertToFloat(
          updatedData.cityFederalAndTennesseeMotorFuelTax
        ) +
        removeCommasAndConvertToFloat(
          updatedData.cityFederalAndTennesseeTobaccoTaxOnAllOtherTobaccoProducts
        ) +
        removeCommasAndConvertToFloat(
          updatedData.cityFederalAndTennesseeTobaccoTaxOnCigarettes
        ) +
        removeCommasAndConvertToFloat(
          updatedData.cityFederalAndTennesseeBeerTax
        ) +
        removeCommasAndConvertToFloat(
          updatedData.cityTennesseeSpecialTaxOnPetroleumProducts
        ) +
        removeCommasAndConvertToFloat(
          updatedData.cityTennesseeLiquifiedGasTaxForCertainMotorVehicles
        ) +
        removeCommasAndConvertToFloat(
          updatedData.cityTennesseeBeerWholesaleTax
        ) +
        removeCommasAndConvertToFloat(updatedData.cityTennesseeBailBondsTax) +
        removeCommasAndConvertToFloat(
          updatedData.cityOtherDeductionsNotTakenElsewhereOnReturn
        );

      updatedData.countyTaxableGrossStateSales =
        removeCommasAndConvertToFloat(updatedData.countyGrossSales) -
        removeCommasAndConvertToFloat(updatedData.countyTotalDeductions);

      updatedData.cityTaxableGrossStateSales =
        removeCommasAndConvertToFloat(updatedData.cityGrossSales) -
        removeCommasAndConvertToFloat(updatedData.cityTotalDeductions);

      console.log('Tax Rate from handle input:', updatedData.taxRate);

      updatedData.countyGrossTax =
        removeCommasAndConvertToFloat(updatedData.taxRate)/100 *
        removeCommasAndConvertToFloat(updatedData.countyTaxableGrossStateSales) ;

      updatedData.cityGrossTax =
        removeCommasAndConvertToFloat(updatedData.taxRate)/100  *
        removeCommasAndConvertToFloat(updatedData.cityTaxableGrossStateSales) ;

      updatedData.countyPersonalPropertyTaxCredit = Math.min(
        removeCommasAndConvertToFloat(updatedData.countyPropertyTax),
        removeCommasAndConvertToFloat(updatedData.countyGrossTax) * 0.5
      );
      updatedData.cityPersonalPropertyTaxCredit = Math.min(
        removeCommasAndConvertToFloat(updatedData.cityPropertyTax),
        removeCommasAndConvertToFloat(updatedData.cityGrossTax) * 0.5
      );

      updatedData.countyNetTax =
        removeCommasAndConvertToFloat(updatedData.countyGrossTax) -
        removeCommasAndConvertToFloat(
          updatedData.countyPersonalPropertyTaxCredit
        );
      updatedData.cityNetTax =
        removeCommasAndConvertToFloat(updatedData.cityGrossTax) -
        removeCommasAndConvertToFloat(
          updatedData.cityPersonalPropertyTaxCredit
        );

      updatedData.totalBusinessTax =
        removeCommasAndConvertToFloat(updatedData.cityNetTax) +
        removeCommasAndConvertToFloat(updatedData.countyNetTax);

      return updatedData;
    });

    return true;
  };

  // const salesData = [
  //   { month: 'January', sales: '$0.00' },
  //   { month: 'February', sales: '$0.00' },
  //   { month: 'March', sales: '$0.00' },
  //   { month: 'April', sales: '$0.00' },
  //   { month: 'May', sales: '$0.00' },
  //   { month: 'June', sales: '$0.00' },
  //   { month: 'July', sales: '$0.00' },
  //   { month: 'August', sales: '$0.00' },
  //   { month: 'September', sales: '$0.00' },
  //   { month: 'October', sales: '$0.00' },
  //   { month: 'November', sales: '$0.00' },
  //   { month: 'December', sales: '$300,460.00' }
  // ];

  const mapSalesData = (clientSalesData) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

    if (!clientSalesData || !Array.isArray(clientSalesData.data)) {
      console.warn(
        'clientSalesData.data is missing or not an array',
        clientSalesData
      );
      return months.map((month) => ({ month, sales: '$0.00' }));
    }

    // Create a sales map
    const salesMap = new Map();

    clientSalesData.data.forEach(({ month, totalSales }) => {
      const numericSales = parseFloat(totalSales || '0'); // Ensure numeric value
      salesMap.set(
        month,
        `${numericSales.toLocaleString('en-US', { minimumFractionDigits: 2 })}`
      );
    });

    return months.map((month) => ({
      month,
      sales: salesMap.get(month) || '0.00'
    }));
  };

  const salesData = mapSalesData(clientSalesData);

  // const totalSales = salesData.reduce((sum, data) => {
  //   const numericValue = Number(data.sales.replace(/[^0-9.-]+/g, '')); // Remove $ and commas
  //   return sum + numericValue;
  // }, 0);
  const totalSales = salesData
    .reduce((sum, { sales }) => {
      const numericValue =
        parseFloat(String(sales).replace(/[^0-9.-]+/g, '')) || 0;
      return sum + numericValue;
    }, 0)
    .toLocaleString('en-US');

  return (
    <div>
      {' '}
      <Modal
        open={open}
        onClose={onClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backdropFilter: 'blur(8px)', // Adjust blur intensity as needed
          WebkitBackdropFilter: 'blur(8px)'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '5%',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '90%',
            height: '85%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '10px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none' // Hide scrollbar for Chrome/Safari
            },
            msOverflowStyle: 'none', // Hide scrollbar for IE and Edge
            scrollbarWidth: 'none'
          }}
        >
          <CloseIcon
            sx={{
              position: 'fixed',
              top: 10,
              right: 10,
              cursor: 'pointer',
              zIndex: 2
            }}
            onClick={resetClose}
          />

          <Typography
            variant="h3"
            component="div"
            sx={{ marginBottom: 2, color: '#1976D2' }}
          >
            {action === 'add'
              ? 'Add Business Tax'
              : businessTaxes.client.legalBusinessName}
          </Typography>

          {loading && (
            <div className="loader-wrapper" style={{ textAlign: 'center' }}>
              <BarLoader
                color={'#1976D2'}
                loading={loading}
                width={'inherit'}
              />
            </div>
          )}

          <Grid container spacing={2}>
            {/* Left Side - 30% for Table */}
            <Grid item xs={12} md={4}>
              <Box sx={{ p: 2, borderRadius: '8px' }}>
                <Grid container sx={{ mb: 3 }} width={'100%'}>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    sx={{ width: '100%' }}
                  >
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        Legal Name Of Business
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        value={clientData?.legalBusinessName || ''}
                        onChange={(e) =>
                          handleInputChange('clientName', e.target.value)
                        }
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                          readOnly: true,
                          style: { backgroundColor: '#f5f5f5' }
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    sx={{ width: '100%' }}
                  >
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">Reporting Period</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        value={businessTaxes.year || ''}
                        onChange={(e) =>
                          handleInputChange('reportingPeriod', e.target.value)
                        }
                        error={!!validationErrors.totalSales}
                        helperText={validationErrors.totalSales}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                          readOnly: true,
                          style: { backgroundColor: '#f5f5f5' }
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    sx={{ width: '100%' }}
                  >
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">Due Date</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        value={reportingPeriodDate || ''}
                        onChange={(e) =>
                          handleInputChange('dueDate', e.target.value)
                        }
                        error={!!validationErrors.totalSales}
                        helperText={validationErrors.totalSales}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                          readOnly: true,
                          style: { backgroundColor: '#f5f5f5' }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            fontWeight: '800',
                            color: '#1976D2',
                            border: '1px solid #E3E1D9',
                            borderCollapse: 'collapse',
                            padding: 5
                          }}
                        >
                          Month
                        </TableCell>
                        <TableCell
                          style={{
                            fontWeight: '800',
                            color: '#1976D2',
                            border: '1px solid #E3E1D9',
                            borderCollapse: 'collapse',
                            padding: 5
                          }}
                        >
                          Sales
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {salesData.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell
                            style={{
                              fontWeight: '800',
                              color: '#1976D2',
                              border: '1px solid #E3E1D9',
                              borderCollapse: 'collapse',
                              padding: 5
                            }}
                          >
                            {data.month}
                          </TableCell>
                          <TableCell sx={styles.cell}>{data.sales}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    {/* <TableRow>
                      <TableCell>
                        <strong>Total</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{totalSales.toLocaleString()}</strong>
                      </TableCell>
                    </TableRow> */}
                  </Table>
                </TableContainer>
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  sx={{ width: '100%', mt: 1 }}
                >
                  <Grid item xs={12} sm={7}>
                    <Typography variant="body1">Total Sales</Typography>
                  </Grid>
                  <Grid item xs={12} sm={2.5}>
                    <TextField
                      value={Number(businessTaxes.totalSales).toFixed(2)}
                      name="totalSales"
                      placeholder="Enter Total Sales"
                      variant="outlined"
                      size="small"
                      error={!!validationErrors.totalSales}
                      helperText={validationErrors.totalSales}
                      InputProps={{
                        readOnly: true,
                        style: { backgroundColor: '#f5f5f5' }
                      }}
                      sx={{
                        '& .MuiInputBase-root': {
                          height: 28 // Set your desired height here
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2.5}></Grid>
                </Grid>
                {/* Property Tax */}
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <CountyCityInput
                    showLabels
                    label="Property Tax"
                    countyValue={businessTaxes.countyPropertyTax}
                    cityValue={businessTaxes.cityPropertyTax}
                    handleInputChange={handleInputChange}
                    countyValidationField={validationErrors.countyPropertyTax}
                    cityValidationField={validationErrors.cityPropertyTax}
                    name="PropertyTax"
                    placeholder="Property Tax"
                  />
                </Grid>
              </Box>
            </Grid>

            {/* Right Side - 70% for Form */}
            <Grid item xs={12} md={8}>
              <form onSubmit={handleSubmit}>
                <Grid spacing={2}>
                  {/* Client Input */}
                  <Grid container spacing={2}>
                    {/* Client Field */}
                    <Grid item xs={6}>
                      <InputLabel htmlFor="client">Client</InputLabel>
                      <Autocomplete
                        id="client"
                        options={clients}
                        getOptionLabel={(client) =>
                          client.legalBusinessName || ''
                        }
                        value={businessTaxes.client}
                        onChange={(_, newValue) =>
                          handleInputChange('client', newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    </Grid>

                    {/* Year Dropdown */}
                    <Grid item xs={6}>
                      <Dropdown
                        value={businessTaxes.year}
                        label="Year"
                        placeholder="Select Year"
                        handleInputChange={handleInputChange}
                        validationField={validationErrors.year}
                        name="year"
                        options={yearsArray.map(String)}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    sx={{ width: '100%' }}
                  >
                    <Grid item xs={12} sm={7}></Grid>
                    <Grid item xs={12} sm={2.5}>
                      <Typography variant="h6">County</Typography>
                    </Grid>{' '}
                    <Grid item xs={12} sm={2.5}>
                      <Typography variant="h6">City</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <CountyCityInput
                      label="Gross Sales"
                      countyValue={Number(
                        businessTaxes.countyGrossSales
                      ).toFixed(2)}
                      cityValue={Number(businessTaxes.cityGrossSales).toFixed(
                        2
                      )}
                      handleInputChange={handleInputChange}
                      countyValidationField={validationErrors.countyGrossSales}
                      cityValidationField={validationErrors.cityGrossSales}
                      name="GrossSales"
                      placeholder="Gross Sales"
                      props={{
                        readOnly: true,
                        style: { backgroundColor: '#f5f5f5' }
                      }}
                    />
                  </Grid>
                  {/* Total Sales */}
                  {/* Deductions Heading */}
                  {/* <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    sx={{ mt: 2, mb: 2, borderBottom: '1px solid #ddd' }}
                  >
                    Deductions
                  </Typography>
                </Grid> */}
                  {deductionFieldKeys.map((fieldKey, index) => (
                    <Grid item xs={12} key={index}>
                      <CountyCityInput
                        label={deductionFieldLabels[index]} // Get the label from the new array
                        countyValue={businessTaxes[`county${fieldKey}`]}
                        cityValue={businessTaxes[`city${fieldKey}`]}
                        handleInputChange={handleInputChange}
                        countyValidationField={
                          validationErrors[`county${fieldKey}`]
                        }
                        cityValidationField={
                          validationErrors[`city${fieldKey}`]
                        }
                        name={fieldKey}
                        placeholder={deductionFieldLabels[index]}
                      />
                    </Grid>
                  ))}
                  {/* Total Deductions */}
                  <Grid item xs={12}>
                    <CountyCityInput
                      label="Total Deductions"
                      countyValue={Number(
                        businessTaxes.countyTotalDeductions
                      ).toFixed(2)}
                      cityValue={Number(
                        businessTaxes.cityTotalDeductions
                      ).toFixed(2)}
                      handleInputChange={handleInputChange}
                      countyValidationField={
                        validationErrors.countyTotalDeductions
                      }
                      cityValidationField={validationErrors.cityTotalDeductions}
                      name="TotalDeductions"
                      placeholder="Total Deductions"
                      readOnly={true}
                      props={{
                        readOnly: true,
                        style: { backgroundColor: '#f5f5f5' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CountyCityInput
                      label="Taxable Gross State Sales"
                      countyValue={Number(
                        businessTaxes.countyTaxableGrossStateSales
                      ).toFixed(2)}
                      cityValue={Number(
                        businessTaxes.cityTaxableGrossStateSales
                      ).toFixed(2)}
                      handleInputChange={handleInputChange}
                      countyValidationField={
                        validationErrors.countyTaxableGrossStateSales
                      }
                      cityValidationField={
                        validationErrors.cityTaxableGrossStateSales
                      }
                      name="TaxableGrossStateSales"
                      placeholder="Taxable Gross State Sales"
                      readOnly={true}
                      props={{
                        readOnly: true,
                        style: { backgroundColor: '#f5f5f5' }
                      }}
                    />
                  </Grid>

                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    sx={{ width: '100%' }}
                  >
                    <Grid item xs={12} sm={7}>
                      <Typography variant="body1">Tax Rate</Typography>
                    </Grid>
                    <Grid item xs={12} sm={2.5}>
                      <TextField
                        value={Number(businessTaxes.taxRate).toFixed(4)}
                        onChange={(e) =>
                          handleInputChange('taxRate', e.target.value)
                        }
                        error={!!validationErrors.taxRate}
                        helperText={validationErrors.taxRate}
                        variant="outlined"
                        // fullWidth
                        size="small"
                        InputProps={{
                          readOnly: true,
                          style: { backgroundColor: '#f5f5f5', height: 28 } // Adjust height here
                        }}
                        sx={{
                          '& .MuiInputBase-root': {
                            height: 28 // Set your desired height here
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={2.5}></Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <CountyCityInput
                      label="Gross Tax"
                      countyValue={Number(businessTaxes.countyGrossTax).toFixed(
                        2
                      )}
                      cityValue={Number(businessTaxes.cityGrossTax).toFixed(2)}
                      handleInputChange={handleInputChange}
                      countyValidationField={validationErrors.countyGrossTax}
                      cityValidationField={validationErrors.cityGrossTax}
                      name="GrossTax"
                      placeholder="Gross Tax"
                      readOnly={true}
                      props={{
                        readOnly: true,
                        style: { backgroundColor: '#f5f5f5' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CountyCityInput
                      label="Personal Property Tax Credit"
                      countyValue={Number(
                        businessTaxes.countyPersonalPropertyTaxCredit
                      ).toFixed(2)}
                      cityValue={Number(
                        businessTaxes.cityPersonalPropertyTaxCredit
                      ).toFixed(2)}
                      handleInputChange={handleInputChange}
                      countyValidationField={
                        validationErrors.countyPersonalPropertyTaxCredit
                      }
                      cityValidationField={
                        validationErrors.cityPersonalPropertyTaxCredit
                      }
                      name="PersonalPropertyTaxCredit"
                      placeholder="Personal Property Tax Credit"
                      readOnly={true}
                      props={{
                        readOnly: true,
                        style: { backgroundColor: '#f5f5f5' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CountyCityInput
                      label="Net Tax"
                      countyValue={Number(businessTaxes.countyNetTax).toFixed(
                        2
                      )}
                      cityValue={Number(businessTaxes.cityNetTax).toFixed(2)}
                      handleInputChange={handleInputChange}
                      countyValidationField={validationErrors.countyNetTax}
                      cityValidationField={validationErrors.cityNetTax}
                      name="NetTax"
                      placeholder="NetTax"
                      readOnly={true}
                      props={{
                        readOnly: true,
                        style: { backgroundColor: '#f5f5f5' }
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    sx={{ width: '100%' }}
                  >
                    {/* Label Section */}
                    <Grid item xs={12} sm={7}>
                      <Typography variant="body1">
                        Total Business Tax
                      </Typography>
                    </Grid>

                    {/* Input Section */}
                    <Grid item xs={12} sm={2.5}>
                      <TextField
                        variant="outlined"
                        // fullWidth
                        size="small"
                        value={Number(businessTaxes.totalBusinessTax).toFixed(
                          2
                        )}
                        onChange={(e) =>
                          handleInputChange('totalBusinessTax', e.target.value)
                        }
                        error={!!validationErrors.totalBusinessTax}
                        helperText={validationErrors.totalBusinessTax}
                        InputProps={{
                          readOnly: true,
                          style: {
                            backgroundColor: '#F9E8C9' // Background color for read-only field
                          }
                        }}
                        sx={{
                          '& .MuiInputBase-root': {
                            height: 28 // Adjusted height for better usability
                          }
                        }}
                        placeholder="Enter Total Business Tax"
                      />
                    </Grid>
                    <Grid item xs={6} sm={2.5}></Grid>
                  </Grid>

                  {/* Buttons */}
                  <Grid item xs={12} sx={{ mt: 4 }}>
                    <Grid container spacing={2} justifyContent="flex-end">
                      <Grid item>
                        <Button
                          variant="contained"
                          color="error"
                          size="medium"
                          sx={{ fontSize: '1rem' }}
                          onClick={onClose}
                        >
                          Close
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          size="medium"
                          sx={{ fontSize: '1rem' }}
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

const removeCommasAndConvertToFloat: any = (value: any) => {
  if (value !== null && value !== undefined && value !== '') {
    // Remove commas and ensure a valid format for floating-point numbers
    const formattedValue = value
      .toString()
      .replace(/,/g, '')
      .replace(/[^\d.]/g, '');

    // Convert to float
    const floatValue = parseFloat(formattedValue);

    if (!isNaN(floatValue)) {
      return floatValue;
    }
  }

  return 0; // or any default value you want to return for invalid inputs
};
const getYears = (startYear, endYear) => {
  const years = [];
  for (let year = startYear; year >= endYear; year--) {
    years.push(year);
  }
  return years;
};
const getMonths = () => {
  const months = [];
  const startDate = new Date(Date.parse('01 January 2000')); // Start from January
  const endDate = new Date(Date.parse('01 January 1999')); // End before January (arbitrary date)
  while (startDate > endDate) {
    const monthName = startDate.toLocaleString('default', { month: 'long' });
    months.push(monthName);
    startDate.setMonth(startDate.getMonth() - 1);
  }
  return months.reverse(); // Reverse the array to display January to December
};
const calculateTax: any = (amount, taxRate) => {
  return (
    (removeCommasAndConvertToFloat(amount) *
      removeCommasAndConvertToFloat(taxRate)) /
    100
  );
};

export default BusinessTaxForm;
